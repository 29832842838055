import Swiper from 'swiper';

export function slideTour() {
  const swiper = new Swiper('.js-slideTour', {
    slidesPerView: 1.8,
    spaceBetween: 16,
    grabCursor: true,
    breakpoints: {
      768: {
        slidesPerView: 4.9,
        spaceBetween: 16,
      },
    },
  });
}

import ScrollMagic from 'scrollmagic';

export function scrollTrigger() {
  const controller = new ScrollMagic.Controller();
  const fadeIn = document.querySelectorAll('.js-scrollAnimation');

  for (let i = 0; i < fadeIn.length; i++) {
    const tag = fadeIn[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onEnter',
      offset: 100,
      reverse: false,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controller);
  }

  const controllerHero = new ScrollMagic.Controller();
  const fadeHero = document.querySelectorAll('.js-introAnimation');

  for (let i = 0; i < fadeHero.length; i++) {
    const tag = fadeHero[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onCenter',
      offset: -200,
      reverse: false,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controllerHero);
  }
}
